import React from "react";
import { useNavigate } from "react-router-dom";

export default function Educationcard({application, newUser, mobile}) {
  console.log(application,newUser,'application in edu')
  const {userId, applicationId, panId,status} = application ? application : {}
 
  const body={
    mobile,
    // course: studentCourse,
    // fees: courseFees,
    // studentName: studentName,
    // instituteName: instituteName,
    // studentEmail:studentEmail,
    userId,
    applicationId,
    channelId:2,
    flow:'apply-now'
  }
  const sendBody = btoa(JSON.stringify(body))

  const redirectUser = () => {
    if(newUser && status === "In Submission"){
      window.open(`${process.env.REACT_APP_PAYLATER_FRONTEND}/pan-details/${sendBody}`, '_self')
    }
  }
 
  return (
      <>
     
          {redirectUser()}
         
      </>
  );
}
