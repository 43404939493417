import { useEffect, useState } from "react";
import styles from "./index.module.css";
import PaybackIcon from "../../../../pay-later/images/icons/payback.svg";
import ZeropcIcon from "../../../../pay-later/images/icons/zero_pc.svg";
import Button from "../../../../pay-later/components/atoms/Button";
import moment from "moment";
import { newFormatToIndianRupees } from "../../../../pay-later/utils/formatToIndianRpuees";

function SanctionLetter(props : any) {
  const {application, setOpenCard} = props
  console.log(application, 'app in sanction letter')
  const { applicationId, userId } = application
  const [product, setProduct] = useState<{
    id?: number;
    applicationId?: string;
    fundCode?: string;
    productId?: string;
    loanAmount?: number;
    emi?: number;
    emiFirstDate?: string;
    dayOfEmi?: number;
    sanctionLetterUrl?: string;
    productDetails?: any;
  }>({});

  useEffect(() => {

    const getProductsDetails = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_DASHBOARD_URL}/admin/application/sanction-details?applicationId=${applicationId}`
        );
        const result = await response.json();
        console.log(result);
        setProduct(result.data);

        const response1 = await fetch(`${process.env.REACT_APP_DASHBOARD_URL}/login/auth`, {
          method: "POST",
          body: JSON.stringify({
            memberId: userId,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });

        const result1 = await response1.json();
        const authToken = result1.data;

        const response2 = await fetch(
          `${process.env.REACT_APP_DASHBOARD_URL}/products/id?productId=${result.data.productId}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        const result2 = await response2.json();
        console.log(result2);

        setProduct((prev) => ({
          ...prev,
          productDetails: result2.data,
        }));
      } catch (error) {
        console.log(error);
      }
    };

    getProductsDetails();

  }, []);

  return (
        <div className={styles.container}>
          <div className={styles.content}>
            <div
              style={{
                flex: 1,
                marginLeft: "1em",
              }}
            >
              <p
                style={{
                  color: "#606060",
                  fontSize: "0.8em",
                  fontWeight:'bold'
                }}
              >
                Principal amount
              </p>
              <p
                style={{
                  fontWeight: 600,
                  fontSize: "1em",
                }}
              >
                 {newFormatToIndianRupees(product?.loanAmount || 0)}
              </p>
            </div>
            <div className={styles.advert}>
          
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  alignItems: "center",
                }}
              >
                <div>
                  <img
                    src={ZeropcIcon}
                    alt=""

                    className= {styles.firstimages}
                    
                  />
                </div>
                <div>
                  <p className= {styles.prinicpalAmountText}
                    
                  >
                    Foreclosure Charges
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  alignItems: "center",
                }}
              >
                <div>
                  <img
                    src={PaybackIcon}
                    alt=""
                    
                    className= {styles.images}
                  />
                </div>
                <div>
                  <p className= {styles.prinicpalAmountText}
                    
                  >
                    Pay Back Anytime
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.content}>
            <p
              style={{
                fontWeight: 600,
                fontSize: "1em",
              }}
            >
              Your EMI plan
            </p>
            <div
              style={{
                marginLeft: "20px",
                marginBottom: "10px",
                borderRadius: "10px",
                background: "#FFF",
                height: "8em",
                width: "18em",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "1.3em",
                  textAlign: "center",
                }}
              >
                {newFormatToIndianRupees(product?.emi || 0)}
              </p>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "1em",
                  textAlign: "center",
                }}
              >
                {product?.productDetails?.tenure} months
              </p>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "1em",
                  textAlign: "center",
                }}
              >
                Start Date: {moment(product?.emiFirstDate).format("DD/MM/YYYY")}
              </p>
            </div>
         
          </div>
          <div className={styles.content}>
            <p>Sanction Letter: </p>
            <p
              className={styles.downloadButton}
              onClick={() => {
                fetch(
                  process.env.REACT_APP_DASHBOARD_URL + "/file/url-content-sl?path=" +
                    product?.sanctionLetterUrl?.split("amazonaws.com/")[1]
                )
                  .then((response) => response.json())
                  .then((data) => {
                    // Download the base64 to pdf
                    const linkSource = `${data.data}`;
                    const downloadLink = document.createElement("a");
                    const fileName = "sanction-letter.pdf";
                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click();
                  });
              }}
            >
              Download
            </p>
          </div>
          <Button  onPress={() => {
            setOpenCard('bank-statement-evaluation')
            }} text={"Proceed"} />
    </div>
  );
}

export default SanctionLetter;
