import React from 'react'
import { TextField } from '@mui/material'

export default function CustomTextField({type='text', label, handleTextFieldOnChange, value}) {
  
  return (
    <TextField
    onChange={(e) => handleTextFieldOnChange(e.target.value)}
      value={value}
      type={type}
      size='small'
      label={label}
       InputProps={{
        style:{fontFamily:'Outfit', },
      }}
      InputLabelProps={{
          style:{
            left:'-40px',
            top:'-12px',
          },
           sx: {bgcolor: "#fffff",fontFamily:'Outfit', fontWeight:'bold', borderRadius:'5px', fontSize:'16px', lineHeight:'20.25px', padding:type==='date' ? '2px 25px 2px 8px': '2px 8px 2px 8px', color:'#000', },  

        }}
      sx={{width:300, mb:2,
          '@media screen and (max-width: 768px)':{
            width:280
          },
          "& label.Mui-focused": {
            marginLeft:'32px',
            color: "#000",
            marginRight:'10px',
            background:'transparent',
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#707C8B",
             
            },
            "&:hover fieldset": {
              borderColor: "#707C8B",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#707C8B",
            },         
          },}}
      />
  )
}
