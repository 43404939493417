import React, { useState, useEffect } from "react";
import Button from "../../../../pay-later/components/atoms/Button";
import styles from "./index.module.css";
import ArrowRight from "../../../../pay-later/images/icons/arrow_right.svg";
import bigcalendar from "../../../../pay-later/images/icons/bigcalendar.svg";
import bigemi from "../../../../pay-later/images/icons/bigemi.svg";
import emi from "../../../../pay-later/images/icons/emi.png"
import biginterest from "../../../../pay-later/images/icons/biginterest.svg";
import axios from "axios";
import { newFormatToIndianRupees } from "../../../../pay-later/utils/formatToIndianRpuees";
import { getNextFifthDate } from "../../../../pay-later/utils/getNextFifthDate";
import { Radio } from "@mui/material";
import SanctionLetter from "../SanctionLetter";
import BankStatementEvaluation from "../BankStatementEvaluate";

function Sanctions(props: any) {
  const {application, stage} = props
  console.log(application, 'app in santions')
  const { applicationId, courseFees } = application
  const[openCard, setOpenCard] = useState(stage >= 8? '': 'select-emi')
  const [products, setProducts] = useState([{ tenure: 0, },]);
  const [activeEmiPlan, setActiveEmiPlan] = useState("");
  const [emiAmount, setEmiAmount] = useState("");
 
  useEffect(() => {
    fetch(`${process.env.REACT_APP_DASHBOARD_URL}/products/instituteId?instituteId=B2C`)
      .then((response) => response.json())
      .then((data) => {
        setProducts(data.data);
      });
  }, []);


  const handleButtonClick = async () => {
    const updateStatusUrl = `${process.env.REACT_APP_DASHBOARD_URL}/admin/application/assign-fund-source`;

    let emidate=new Date()
    let current=null
    if (emidate.getMonth() == 11) {
      current = `${emidate.getFullYear() + 1}/01/05`
    } else {
      current = `${emidate.getFullYear()}-${emidate.getMonth() + 2}-05`
    }
    const statusData = {
      applicationId: applicationId,
      fundCode: "GBP",
      productId: activeEmiPlan,
      loanAmount: courseFees,
      emi: Number(emiAmount),
      emiFirstDate:current,
      dayOfEmi: 5,
      rulesStatus:1
    };
    axios.post(`${updateStatusUrl}`, statusData, {
              headers: {
                Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcHBsaWNhdGlvbnMtYmFja2VuZCIsInVzZXJfaWQiOjUsImVtYWlsIjoic2FpcmFta0BmZWVtb25rLmNvbSIsImlhdCI6MTcxNjg3Mzc4NjY5Nywicm9sZSI6ImNybSIsImRpc3BsYXlfbmFtZSI6IlNhaSBSYW0gUmVkZHkiLCJtb2JpbGUiOiI2MzA5MTQ4MzMzIiwiZXhwIjoxNzE2ODc0MzkxNDk3fQ.lPHnxTija-VpfqMzFWfJJ5fkHDkWJYM-kt2ybO7AgA8`,
                'Content-type': 'application/json',
              },
    })
      .then((res) => {
          if (res.data.message === 'Successful') {
             setOpenCard('sanction-letter')
          }
        },
      ).catch((e)=>{
        console.log(e, 'assign-found-source-error')
      })

  };

  return (
    <>
    {openCard==='select-emi' && 
       <div className={styles.container}>
       <div className={styles.main}>
         <div
           style={{
             background: "#FFF7F2",
             border: "1px solid #F9D8D6",
             borderRadius: "12px 12px 0px 0px",
           }}
         >
           <p
             style={{
               fontSize: "1.5rem",
               textAlign: "center",
               fontWeight: "bold",
               marginTop:'12px'
             }}
           >
              {newFormatToIndianRupees(courseFees)}
           </p>
         </div>
         <div
           style={{
             display: "flex",
             flexDirection: "column",
             border: "1px solid #F9D8D6",
             background: "#ffffff",
             paddingTop:'12px',
             boxShadow: "0px 3px 3px rgba(211, 32, 40, 0.1)",
             borderRadius: "0px 0px 12px 12px",
           }}
         >
           <div
             style={{
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
               gap: "2rem",
             }}
           >
             <div
               style={{
                 display: "flex",
                 flexDirection: "column",
                 alignItems: "center",
                 flex: 1,
               }}
             >
               <img src={bigemi} className={styles.image}  />
               <p  className={styles.cardTitles}>EMI</p>
               <p style={{ fontSize: "0.875rem", fontWeight: "bold" }}>
                 {activeEmiPlan
                   ? `${newFormatToIndianRupees(
                       Number(
                         Number(
                           courseFees/
                             Number(
                               products
                                 .sort((a, b) => a.tenure - b.tenure)
                                 .find(
                                   (product: any) =>
                                     product.productId === activeEmiPlan
                                 ) &&
                                 products
                                   .sort((a, b) => a.tenure - b.tenure)
                                   .find(
                                     (product: any) =>
                                       product.productId === activeEmiPlan
                                   )?.tenure
                             )
                         ).toFixed(2)
                       )
                     )}`
                   : "₹ 0"}
               </p>
             </div>
             <div
               style={{
                 display: "flex",
                 flexDirection: "column",
                 alignItems: "center",
                 flex: 1,
               }}
             >
               <img
                 src={bigcalendar}
                 className={styles.image}
               />
               <p  className={styles.cardTitles}>TENURE</p>
               <p style={{ fontSize: "0.875rem", fontWeight: "bold" }}>
                 {activeEmiPlan
                   ? products
                       .sort((a, b) => a.tenure - b.tenure)
                       .find(
                         (product: any) => product.productId === activeEmiPlan
                       )?.tenure
                   : "0"}{" "}
                 Months
               </p>
             </div>
             <div
               style={{
                 display: "flex",
                 flexDirection: "column",
                 alignItems: "center",
                 flex: 1,
               }}
             >
               <img
                 src={biginterest}
                 className={styles.image}
               />
               <p className={styles.cardTitles}>
                 Starts On
               </p>
               <p
                 style={{
                   fontSize: "0.875rem",
                   fontWeight: "bold",
                   textDecoration: "underline",
                   color: "#d23028",
                   cursor: "pointer",
                 }}
                 // onClick={() =>
                 //   document.getElementById("emiDateInput")?.focus()
                 // }
               >
                 {getNextFifthDate()}
                 {/* {selectedDate ? formatDate(selectedDate) : "Select Date"} */}
               </p>
             </div>
           </div>
         </div>
         <div
           style={{
             display: "flex",
             justifyContent: "space-between",
             flexDirection: "column",
           }}
         >
           <p className={styles.headerText} >
             Choose EMI plan
           </p>
           <div
             
           >
             {products
               .sort((a, b) => a.tenure - b.tenure)
               .map((product: any) => (
                 <div
                   style={{
                     display: "flex",
                     justifyContent: "space-between",
                     alignItems: "center",
                     padding:'8px 10px',
                     border:
                       activeEmiPlan === product.productId
                         ? "1px solid #D32028"
                         : "1px solid #F9D8D6",
                     borderRadius: "12px",
                     marginBottom: "1rem",
                     background: "#FFF8F4",
                     boxShadow: "0px 3px 3px rgba(211, 32, 40, 0.1)",
                     cursor:'pointer'
                   }}
                   key={product.productId}
                   onClick={() => {
                     setActiveEmiPlan(product.productId);
                     setEmiAmount((courseFees / Number(product.tenure)).toFixed(2))
                   }}
                 >
                   <Radio color="error" checked={product.productId===activeEmiPlan} />
                   <img src={emi} style={{width:'40px'}}/>
                   <p
                     style={{
                       textAlign: "left",
                       fontSize: "1rem",
                       fontWeight: "bold",
                     }}
                   >
                     {newFormatToIndianRupees(
                       Number(
                         Number(courseFees / Number(product.tenure)).toFixed(2)
                       )
                     )}
                   </p>
                   <p
                     style={{
                       textAlign: "center",
                       fontSize: "1rem",
                       // fontWeight: "bold",
                     }}
                   >
                     {product.tenure} Months
                   </p>
                   {/* {activeEmiPlan === product.productId ? <img src={selected}/>:null} */}
                 </div>
               ))}
           </div>
         </div>
         <br />
         <div style={{marginTop:'0.5rem', width:'40%', margin:'0 auto'
         }}> <Button
           text={"Continue"}
           onPress={handleButtonClick}
           imageRight={ArrowRight}
           disabled={!activeEmiPlan && !emiAmount }
           fullWidth
         /></div>
       
       </div>
     </div>
    }
    {
      openCard==='sanction-letter' && 
      <SanctionLetter application={application} setOpenCard={setOpenCard}/>
    }
    {
      openCard==='bank-statement-evaluation' && 
      <BankStatementEvaluation application={application} setOpenCard={setOpenCard}/>
    }
    </>
      
  );
}

export default Sanctions;
