import { useState, useEffect } from "react";
import Button from "../../../components/atoms/Button";
import styles from "./index.module.css";
import ArrowRight from "../../../images/icons/arrow_right.svg";
import Vector from "../../../images/static_assests/parent_img.svg"
import StudentIcons from "../../../images/static_assests/student_icon.svg";
import { useNavigate, useLocation } from "react-router-dom";
import BackArrow from "../../../images/icons/arrow-left-circle.svg"
import Navbar from "../../../components/molecules/Navbar";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { ApplicantStage } from "../../../utils/applicationStage";
import Footer from "../../../components/molecules/Footer";
type CardType = "parent" | "student" | null;

function IdentifyYourself() {
  const location = useLocation();
  const stateData = location?.state?.data || {};
  console.log(stateData, 'identify yourself page');
  const [selectedCard, setSelectedCard] = useState<CardType>(null); 
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    setLoading(true);
    const applicationId = location?.state?.data?.existing ?  location?.state?.data?.applicationId : location?.state?.data?.application
    fetch(
      `${process.env.REACT_APP_DASHBOARD_URL}/admin/application/stage?applicationId=${applicationId}`
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res?.data?.stage) {
          switch (res?.data?.stage) {
            case ApplicantStage.PanVerification:
              navigate("/address-details", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.EmploymentDetails:
              // navigate("/identify-yourself", {
              //   state: { data: location?.state?.data },
              // });
              break;
            case ApplicantStage.AddressDetails:
              navigate("/work-details", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.BankStatement:
              navigate("/view-offers", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.AwaitingApproval:
              navigate("/parking-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.CoapplicantRequired:
              navigate("/parking-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.AwaitingCoapplicantConsent:
              navigate("/parking-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Approved:
              navigate("/select-emi", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.SanctionTermsAccepted:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Digilocker:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Selfie:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Agreement:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.BankAccountVerified:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Mandate:
              navigate("/disbursed-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.PFCollections:
              navigate("/pf-collection", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.ProcessComplete:
              navigate("/disbursed-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Disbursed:
              navigate("/disbursed-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Rejected:
              navigate("/rejected-page", {
                state: { data: location?.state?.data },
              });
              break;
            default:
              try {
                navigate(`/${atob(JSON.stringify(location?.state?.data))}`);
              } catch (err) {
                navigate(`/pan-details/:data`);
              }
              break;
          }
        }
      })
      .catch((err) => console.log(err));
  }, [navigate])


  const handleCardClick = (cardType: CardType) => {
    setSelectedCard(cardType); 
  };

 const handleProcceed = async () => {
    if(true){
      const data = {...location?.state?.data, selectedCard}
      navigate("/student-details", {
        state: { data:  data},
      });
      return
    }

    //  const data = {
    //   applicationId: location?.state?.data?.existing ?  location?.state?.data?.applicationId : location?.state?.data?.application,
    //   courseFees: stateData.existing ?  stateData?.courseFees : stateData?.fees,
    //   instituteName: stateData?.instituteName,
    //   courseName: stateData.existing ?  stateData?.courseName: stateData?.course,
    //   isCoapplicant: false,
    //   loanAmount:  stateData.existing ? stateData?.courseFees: stateData?.fees,
    //   studentName: stateData.existing ? stateData?.studentName: stateData?.firstName + ' ' + stateData.lastName,
    //   channel:"2"
    // }
    // const authToken = localStorage.getItem("auth_token") || "";
   
    // try {
    //   const response = await fetch(`${process.env.REACT_APP_DASHBOARD_URL}/admin/application/update`, {
    //     method: "POST",
    //     headers: {
    //       Authorization: `Bearer ${authToken}`,
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(data),
    //   });
  
    //   if (!response.ok) {
    //     console.log(response)
    //   }
  
    //   const result = await response.json();
    //   console.log("Profile details updated:", result);
    //   navigate("/bank-statement-upload", {
    //       state: { data: location?.state?.data },
    //   });
    //   return true
    // } catch (error) {
    //   console.log(error, 'error')
    //   return false;
    // }

 }

  return (
    <div className={styles.body}>
        <Navbar/>
      <div className={styles.container}>
      {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <CircularProgress />
            </Box>
          ) :
        <div className={styles.main}>
          <h1 className={styles.headerText}>Identify Yourself!</h1>
          <div className={styles.card}  onClick={() => handleCardClick("parent")} style={{border:selectedCard==='parent'? '1px solid #d32028':'' , background: selectedCard==='parent'? 'linear-gradient(95.71deg, rgba(255, 246, 246, 0.9) -0.64%, rgba(255, 247, 241, 0.9) 104.18%)': ''}} >
            <img src={Vector} alt='parent'/>
            <p className={styles.description}>I am a <span style={{fontWeight:'bold', fontSize:'16px', fontFamily:'Outfit'}}>parent</span> & I am here to apply for my child's education loan</p>
          </div>
          <div className={styles.card}  onClick={() => handleCardClick("student")} style={{border:selectedCard==='student'? '1px solid #d32028':'' , background: selectedCard==='student'? 'linear-gradient(95.71deg, rgba(255, 246, 246, 0.9) -0.64%, rgba(255, 247, 241, 0.9) 104.18%)': '' }} >
            <img src={StudentIcons} alt='student'/>
            <p className={styles.description}>I am the <span style={{fontWeight:'bold', fontSize:'16px', fontFamily:'Outfit'}}>student</span> & I am looking for loan to support my education</p>
          </div>
          <br />
          <div style={{marginTop:'2px', alignSelf:'center', width:'30%'}}>
              <Button
                text="Proceed"
                onPress={handleProcceed}
                fullWidth
                imageRight={ArrowRight}
                disabled={!selectedCard} 
              />
          </div>
         
        
        </div>
      } 
      </div>
      <Footer/>
    </div>
  );
}

export default IdentifyYourself;
