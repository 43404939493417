import { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Grid, Box } from "@mui/material";
import rupee from "../../../../Assets/images/rupee.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import calender from "../../../../Assets/images/calender.png";
import  './index.css'
export default function Disbursed(props: any) {
  const {item, stage} = props
  const [openCard, setOpenCard] = useState(item?.obj?.applicationProfile?.disbursement ? 'details' : stage===15? 'under-disbursed': stage===18? 'rejected':'')
  console.log(openCard, 'card')
  return (
    <>
   {openCard ==='details' && 
   <>
          <Accordion
            style={{
              marginLeft: "1em",
              marginRight: "1em",
              marginBottom: "1em",
            }}
            defaultExpanded={true}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <span
                style={{
                  fontFamily: "Outfit-Medium",
                  fontSize: "1em",
                  color: "black",
                }}
              >
                Disbursement
              </span>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Grid
                  item
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    borderStyle: "solid",
                    boxShadow:
                      "1px 1px 1px rgba(0, 0, 0, 0.2)",
                    borderWidth: "1px",
                    borderColor: "#EAECF0",
                    borderRadius: "5px",
                    paddingTop: "15px",
                    paddingBottom: "10px",
                    marginTop: "5px",
                  }}
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                >
                  <div>
                    <p
                      style={{
                        fontFamily: "Outfit-Medium",
                        color: "#858585",
                        fontWeight: "500",
                        fontSize: "1em",
                      }}
                    >
                      Amount Disbursed
                    </p>
                    <p
                      style={{
                        color: "#101828",
                        fontWeight: "600",
                        fontFamily: "Outfit-Medium",
                        fontSize: "1em",
                      }}
                    >
                      {
                        item?.obj?.applicationProfile
                          ?.disbursement
                          ?.disbursementAmount
                      }
                    </p>
                  </div>
                  <div>
                    <img
                      src={rupee}
                      alt="rupee"
                      style={{
                        height: "53px",
                        width: "53px",
                      }}
                    />
                  </div>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    borderStyle: "solid",
                    boxShadow:
                      "1px 1px 1px rgba(0, 0, 0, 0.2)",
                    borderWidth: "1px",
                    borderColor: "#EAECF0",
                    borderRadius: "5px",
                    paddingTop: "15px",
                    paddingBottom: "10px",
                    marginTop: "5px",
                  }}
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                >
                  <div>
                    <p
                      style={{
                        fontFamily: "Outfit-Medium",
                        color: "#858585",
                        fontWeight: "500",
                        fontSize: "1em",
                      }}
                    >
                      Disbursement Date
                    </p>
                    <p
                      style={{
                        color: "#101828",
                        fontWeight: "600",
                        fontFamily: "Outfit-Medium",
                        fontSize: "1em",
                      }}
                    >
                      {item?.obj?.applicationProfile?.disbursement?.disbursementDate?.substr(
                        0,
                        10
                      )}
                    </p>
                  </div>
                  <div>
                    <img
                      src={calender}
                      alt="rupee"
                      style={{
                        height: "53px",
                        width: "53px",
                      }}
                    />
                  </div>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    borderStyle: "solid",
                    boxShadow:
                      "1px 1px 1px rgba(0, 0, 0, 0.2)",
                    borderWidth: "1px",
                    borderColor: "#EAECF0",
                    borderRadius: "5px",
                    paddingTop: "15px",
                    paddingBottom: "10px",
                    marginTop: "5px",
                  }}
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                >
                  <div>
                    <p
                      style={{
                        fontFamily: "Outfit-Medium",
                        color: "#858585",
                        fontWeight: "500",
                        fontSize: "1em",
                      }}
                    >
                      UTR
                    </p>
                    <p
                      style={{
                        color: "#101828",
                        fontWeight: "600",
                        fontFamily: "Outfit-Medium",
                        fontSize: "1em",
                      }}
                    >
                      {
                        item?.obj?.applicationProfile
                          ?.disbursement?.utr
                      }
                    </p>
                  </div>
                  <div>
                    <img
                      src={calender}
                      alt="rupee"
                      style={{
                        height: "53px",
                        width: "53px",
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </>
    }
    {
      openCard === 'under-disbursed' && 
      <div className="disbursed-container">
        <h1 style={{color: "#D32028", fontSize: "30px",fontWeight: "normal", fontFamily:'Outfit'}}>Congratulations </h1>
      <h2 style={{fontSize: "20px", fontWeight: "normal", }}>
        Your loan is under disbursal.
      </h2>
      <div className='holdOnTextContainer'>
        <p className='holdOnHelperText'>
          If you have a problem, please contact us:
        </p>
        <p className='holdOnHelperTextSmall'>hello@feemonk.com</p>
      </div>
      </div>
    }
   
    {
      openCard === 'rejected'&& 
      <div className='disbursed-container'>
      <h2 style={{
          fontSize: "18px",
          fontWeight: "normal",
        }}
      >
        Sorry your loan cannot be approved at this time.
      </h2>
      <div className='holdOnTextContainer'>
        <p className='holdOnHelperText'>
          If you have a problem, please contact us:
        </p>
        <p className='holdOnHelperTextSmall'>hello@feemonk.com</p>
      </div>
    </div>
    }
    </>
  )
 }
