import React from 'react'

export default function Error({setError, error}) {
  return (
    <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100vh",
            backgroundColor: "rgba(0,0,0,0.6)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
          onClick={() => setError("")}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "1rem 1rem",
              borderRadius: "10px",
              boxShadow: "0 0 10px rgba(0,0,0,0.3)",
              margin: "0 1rem",
              
            }}
            onClick={() => setError("")}
          >
            <h5
              style={{
                fontFamily: "Outfit",
                textAlign: "center",
                fontSize:'16px'
              }}
            >
              <span style={{color:'#d32028'}}>Error:{' '}</span>{error}
            </h5>
          </div>
        </div>
  )
}
