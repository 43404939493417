import { useEffect, useState } from "react";
import styles from "./index.module.css";
import GreenTick from "../../../../pay-later/images/icons/sanctions-icons/green-tick.svg";
import NextArrow from "../../../../pay-later/images/icons/sanctions-icons/next-arrow.svg";
import GreyDot from "../../../../pay-later/images/icons/sanctions-icons/grey-dot.svg";
import { useLocation } from "react-router-dom";
import { MandateType } from "../../../../pay-later/utils/types";
import { createNach } from "../../../../pay-later/services/sanctions";
import {
  AgreementStatus,
  DigilockerStatus,
  MandateStatus,
  SelfieStatus,
} from "../../../../pay-later/utils/config";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import PFCollection from "../PFCollection";

let Digio: any;

function Kyc(props: any) {
  const {application} = props
  console.log(application, 'app in kyc')
  const { applicationId, userId, mobile } = application
  const [mandateStatus, setMandateStatus] = useState<MandateType>({});
  const [openCard, setOpenCard] = useState('kyc')
  const location = useLocation();
  const [flag, setFlag] = useState(false);

  const [authToken, setAuthToken] = useState({
    mob: "",
    value: "",
  });


  const [loading, setLoading] = useState(false);

  const [loaderAgreement, setLoaderAgreement] = useState(false);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_DASHBOARD_URL}/integrations-login/generate-token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        mobile,
        userId,
        applicationId
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setAuthToken({
          mob: mobile,
          value: data.data,
        });
        setInterval(() => {
              fetch(`${process.env.REACT_APP_DASHBOARD_URL}/integrations-login/auth`, {
                  headers: {
                    Authorization: `Bearer ${data.data}`,
                    "Content-Type": "application/x-www-form-urlencoded",
                  },
                })
                  .then((res) => res.json())
                  .then((data) => {
                    setOpenCard((data?.data?.enachStatus===4)? 'pf-collection': 'kyc')
                    setMandateStatus(data.data);
                  });
            }, 2000);
      });
      
  }, []);



  const fetchJsFromCDN = (src: any, externals: string[]) => {
    externals = !externals ? (externals = []) : externals;
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.setAttribute("src", src);
      script.addEventListener("load", () => {
        resolve(
          externals.map((key: any) => {
            const ext = window[key];
            typeof ext === "undefined" &&
              console.warn(`No external named '${key}' in window`);
            return ext;
          })
        );
      });
      script.addEventListener("error", reject);
      document.body.appendChild(script);
    });
  };

  const disabledCheckDigiLocker = (mandateStatus: MandateType) => {
    if (mandateStatus?.kycDone) {
      return true;
    } else if (
      !mandateStatus?.kycDone &&
      mandateStatus?.digilockerStatus !== DigilockerStatus.Successful
    ) {
      return false;
    }
    return true;
  };

  const disabledCheckDigiLockerAfterSelfie = (mandateStatus: MandateType) => {
    if (mandateStatus?.digilockerStatus === DigilockerStatus.Successful) {
      return true;
    }
    if (mandateStatus?.selfieStatus === SelfieStatus.Failed) {
      return false;
    }
    if (mandateStatus?.kycDone) {
      return true;
    } else if (
      !mandateStatus?.kycDone &&
      mandateStatus?.digilockerStatus !== DigilockerStatus.Successful
    ) {
      return false;
    }
    return true;
  };

  const disabledCheckSelfie = (mandateStatus: MandateType) => {
    if (
      !mandateStatus?.kycDone &&
      mandateStatus?.digilockerStatus !== DigilockerStatus.Successful
    ) {
      return true;
    }
    if (mandateStatus?.selfieStatus !== SelfieStatus.Successful) {
      return false;
    }
    return true;
  };

  const disabledCheckAgreement = (mandateStatus: MandateType) => {
    if (
      !mandateStatus?.kycDone &&
      mandateStatus?.digilockerStatus !== DigilockerStatus.Successful
    ) {
      return true;
    }
    if (mandateStatus?.selfieStatus === SelfieStatus.Failed) {
      if (mandateStatus?.agreementStatus === AgreementStatus.Signed) {
        return true;
      }
      return false;
    }
    if (mandateStatus?.selfieStatus !== SelfieStatus.Successful) {
      return true;
    }
    if (mandateStatus?.agreementStatus !== AgreementStatus.Signed) {
      return false;
    }
    return true;
  };

  const disabledCheckMandate = (mandateStatus: MandateType) => {
    if (
      !mandateStatus?.kycDone &&
      mandateStatus?.digilockerStatus !== DigilockerStatus.Successful
    ) {
      return true;
    }
    if (mandateStatus?.selfieStatus === SelfieStatus.Failed) {
      if (mandateStatus?.agreementStatus === MandateStatus?.Successful) {
        return true;
      }
      return false;
    }
    if (mandateStatus?.selfieStatus !== SelfieStatus.Successful) {
      return true;
    }
    if (mandateStatus?.agreementStatus === AgreementStatus.PendingCoapplicant) {
      return false;
    }
    if (mandateStatus?.agreementStatus !== AgreementStatus.Signed) {
      return true;
    }
    if (mandateStatus?.enachStatus !== MandateStatus?.Successful) {
      return false;
    }
    return true;
  };



  function digilockerHandler() {
    fetchJsFromCDN(process.env.REACT_APP_DIGIO_SDK, ["Digio"]).then(
      (digio: any) => {
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `Bearer ${authToken && authToken.value}`
        );

        var requestOptions: RequestInit = {
          method: "POST",
          headers: myHeaders,
          redirect: "follow",
        };

        fetch(`${process.env.REACT_APP_DASHBOARD_URL}/digilocker/create`, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            const { kid, tokenId, id } = result.data;

            Digio = digio[0];
            let d = new Digio({
              environment: process.env.REACT_APP_DIGIO_ENV,
              logo: "yourlogourl",
              theme: {
                primaryColor: "#234FDA",
                secondaryColor: "#234FDA",
              },
              is_iframe: true,
              callback: (_digio: any) => {
                // console.log("ALL: ", _digio);

                // delayedUpdateDigioCall(kid, id);

                if (_digio.error_code === "CANCELLED") {
                  // console.log("Flow cancelled by user");
                  setFlag(!flag);
                  // navigate("/sanctions", {
                  //   state: { data: { ...location?.state?.data } },
                  // });
                  // callAuthApiDelayed();
                  return;
                }
                if (_digio.error_code !== undefined) {
                  setFlag(!flag);
                  // navigate("/sanctions", {
                  //   state: { data: { ...location?.state?.data } },
                  // });
                  // callAuthApiDelayed();
                  throw new Error(_digio.message);
                }

                setFlag(!flag);
                // navigate("/sanctions", {
                //   state: { data: { ...location?.state?.data } },
                // });
                // callAuthApiDelayed();

                // console.log(_digio);
              },
            });

            // console.log(d);

            d.init();
            d.submit(kid, authToken.mob, tokenId);
          })
          .catch((error) => console.log("error", error));
      }
    );
  }

  const loanAgreementHandler = () => {
    setLoaderAgreement(true); // Activate loader and disable button
    fetchJsFromCDN(process.env.REACT_APP_DIGIO_SDK, ["Digio"])
      .then((digio: any) => {
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `Bearer ${authToken && authToken.value}`
        );

        var requestOptions: RequestInit = {
          method: "POST",
          headers: myHeaders,
          redirect: "follow",
        };

        fetch(
          `${process.env.REACT_APP_DASHBOARD_URL}/agreement/${
            mandateStatus?.isCoapplicant ? "coapplicant-generate" : "generate"
          }`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            const { email, id, tokenId } = result.data;

            const Digio = digio[0];
            let d = new Digio({
              environment: process.env.REACT_APP_DIGIO_ENV,
              logo: "yourlogourl",
              theme: {
                primaryColor: "#234FDA",
                secondaryColor: "#234FDA",
              },
              digioDocumentId: id,
              digioUserIdentifier: email,
              is_iframe: true,
              callback: (_digio: any) => {
                // API call completed, disable loader and enable button
                setLoaderAgreement(false);
                if (_digio.error_code === "CANCELLED") {
                  setFlag(!flag);
                  // navigate("/sanctions", {
                  //   state: { data: { ...location?.state?.data } },
                  // });
                  return;
                }
                if (_digio.error_code !== undefined) {
                  setFlag(!flag);
                  // navigate("/sanctions", {
                  //   state: { data: { ...location?.state?.data } },
                  // });
                  throw new Error(_digio.message);
                }
                setFlag(!flag);
                // navigate("/sanctions", {
                //   state: { data: { ...location?.state?.data } },
                // });
              },
            });

            d.init();
            d.submit(id, email, tokenId);
          })
          .catch((error) => {
            // API call failed, disable loader and enable button
            setLoaderAgreement(false);
            console.log("error", error);
          });
      })
      .catch((error) => {
        // Fetching digio.js failed, disable loader and enable button
        setLoaderAgreement(false);
        console.log("error", error);
      });
  };
 const data = sessionStorage.getItem("data")
  function selfieHandler() {
    fetch(`${process.env.REACT_APP_DASHBOARD_URL}/selfie/create`, {
      method: "POST",
      headers: {
        // Authorization: `Bearer ${authToken.value}`,
        Authorization: `Bearer ${authToken && authToken.value}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        if (res.message === "success") {
          // console.log(res.data.url);
          console.log(process.env.REACT_APP_DIGIO_BASEURL, "----> redirect to")
          const redirect_url = window.open(
            `${process.env.REACT_APP_DIGIO_BASEURL}/#/gateway/login/${res.data.entityId}/vI3atY/${mobile}?token_id=${res.data.tokenId}&redirect_url=${`${process.env.REACT_APP_PAYLATER_FRONTEND}/mainpage`}`,
            "_self",
            "noopener,noreferrer"
          );
        } else {
          if (
            res.message ===
            "Oops. There is an issue at our end. Please contact our support team"
          ) {
            alert(
              "Please check the internet connection. Contact our support team if the error is not resolved."
            );
          }
        }
      });
  }

  async function getRazorpayUrl() {
    setLoading(true);
    try {
      const response = await createNach({
        userId,
        applicationId,
      });
      window.open(response?.data?.authLink, "_self", "noopener,noreferrer");
    } catch (error) {
      console.error("Error fetching Razorpay URL", error);
    } finally {
      setLoading(false);
      setOpenCard('pf-collection')
    }
  }

  return (
    <>
    {openCard === 'kyc' && 
    <div className={styles.body}>
    <div className={styles.container}>
      <div className={styles.buttonList}>

        {loading ? (
           <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
           <CircularProgress />
         </Box>
        ):(
          <>
           {!mandateStatus?.kycDone && (
          <>
            <div className={styles.sanctionsListGrid}>
              <img
                src={
                  mandateStatus?.digilockerStatus === 3
                    ? GreenTick
                    : GreyDot
                }
                alt=""
                className={styles.sanctionsGreenTick}
              />
              <p
                className={styles.sanctionsProgressBarText}
                style={{
                  color: disabledCheckDigiLocker(mandateStatus)
                    ? "#9a9a9a"
                    : "#000000",
                }}
              >
                Digilocker KYC
              </p>
              {disabledCheckDigiLocker(mandateStatus) ? (
                <div></div>
              ) : (
                <img
                  src={NextArrow}
                  alt=""
                  className={styles.sanctionNextArrow}
                  onClick={() => digilockerHandler()}
                />
              )}
            </div>
            <div className={styles.sanctionsListGrid}>
              <div
                className={
                  mandateStatus?.digilockerStatus === 3
                    ? styles.sanctionsProgressBar
                    : styles.sanctionsProgressBarGrey
                }
              ></div>
              <div></div>
              <div></div>
            </div>
          </>
        )}
        {mandateStatus?.selfieStatus !== SelfieStatus.Failed && (
          <>
            <div className={styles.sanctionsListGrid}>
              <img
                src={
                  mandateStatus?.selfieStatus === 3 ? GreenTick : GreyDot
                }
                alt=""
                className={styles.sanctionsGreenTick}
              />
              <p
                className={styles.sanctionsProgressBarText}
                style={{
                  color: disabledCheckSelfie(mandateStatus)
                    ? "#9a9a9a"
                    : "#000000",
                }}
              >
                Selfie
              </p>
              {disabledCheckSelfie(mandateStatus) ? (
                <div></div>
              ) : (
                <img
                  src={NextArrow}
                  alt=""
                  className={styles.sanctionNextArrow}
                  onClick={() => {
                    selfieHandler();
                  }}
                />
              )}
            </div>
            <div className={styles.sanctionsListGrid}>
              <div
                className={
                  mandateStatus?.selfieStatus === 3
                    ? styles.sanctionsProgressBar
                    : styles.sanctionsProgressBarGrey
                }
              ></div>
              <div></div>
              <div></div>
            </div>
          </>
        )}
        {mandateStatus?.selfieStatus === SelfieStatus.Failed && (
          <>
            <div className={styles.sanctionsListGrid}>
              <img
                src={
                  mandateStatus?.digilockerStatus === 3
                    ? GreenTick
                    : GreyDot
                }
                alt=""
                className={styles.sanctionsGreenTick}
              />
              <p
                className={styles.sanctionsProgressBarText}
                style={{
                  color: disabledCheckDigiLockerAfterSelfie(mandateStatus)
                    ? "#9a9a9a"
                    : "#000000",
                }}
              >
                Digilocker KYC
              </p>
              {disabledCheckDigiLockerAfterSelfie(mandateStatus) ? (
                <div></div>
              ) : (
                <img
                  src={NextArrow}
                  alt=""
                  className={styles.sanctionNextArrow}
                  onClick={() => digilockerHandler()}
                />
              )}
            </div>
            <div className={styles.sanctionsListGrid}>
              <div
                className={
                  mandateStatus?.digilockerStatus === 3
                    ? styles.sanctionsProgressBar
                    : styles.sanctionsProgressBarGrey
                }
              ></div>
            </div>
          </>
        )}
        <>
          <div className={styles.sanctionsListGrid}>
            <img
              src={
                mandateStatus?.agreementStatus === 3 ? GreenTick : GreyDot
              }
              alt=""
              className={styles.sanctionsGreenTick}
            />
            <p
              className={styles.sanctionsProgressBarText}
              style={{
                color:
                  disabledCheckAgreement(mandateStatus) || loaderAgreement
                    ? "#9a9a9a"
                    : "#000000",
              }}
            >
              Agreement
            </p>
            {disabledCheckAgreement(mandateStatus) || loaderAgreement ? (
              <div></div>
            ) : (
              <img
                src={NextArrow}
                alt=""
                className={styles.sanctionNextArrow}
                onClick={() => loanAgreementHandler()}
              />
            )}
          </div>
          <div className={styles.sanctionsListGrid}>
            <div
              className={
                mandateStatus?.agreementStatus === 3
                  ? styles.sanctionsProgressBar
                  : styles.sanctionsProgressBarGrey
              }
            ></div>
          </div>
        </>
        <div className={styles.sanctionsListGrid}>
          <img
            src={mandateStatus?.enachStatus === 4 ? GreenTick : GreyDot}
            alt=""
            className={styles.sanctionsGreenTick}
          />
          <p
            className={styles.sanctionsProgressBarText}
            style={{
              color: disabledCheckMandate(mandateStatus)
                ? "#9a9a9a"
                : "#000000",
            }}
          >
            e-Mandate
          </p>
          {disabledCheckMandate(mandateStatus) ? (
            <div></div>
          ) : (
            <img
              src={NextArrow}
              alt=""
              className={styles.sanctionNextArrow}
              onClick={() =>{ 
                getRazorpayUrl()
              }
              }
            />
          )}
        </div>
          </>
        )}
       

        {process.env.REACT_APP_CURRENT === "dev" ?(
          <button
          onClick={() => {
            console.log("Clicked");

            fetch(`${process.env.REACT_APP_DASHBOARD_URL}/nach/skip`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                userId,
                applicationId,
              }),
            }).then((res) => {
                setOpenCard('pf-collection')
            })
          }}
        >
          Skip (for dev purposes)
        </button>
        ):null}
        
      </div>

          <p className={styles.footerText}>
            Powered By
            <img
              className={styles.footerImage}
              src="https://www.digio.in/images/digio_blue.png"
              alt=""
            />
          </p>
    </div>
    </div>
   } 

   {
    openCard === 'pf-collection' && <PFCollection application={application}/>
   }
    </>
   
   
        
  );
}

export default Kyc;

